import React, {ChangeEvent, Fragment} from "react"
import {Col, Form, Row, Spinner} from "react-bootstrap"
import {data} from "../../utils/Data"
import {useAppDispatch, useAppSelector} from "../../redux/Hooks"
import {RootState} from "../../redux/Store"
import {displayMessage} from "../../redux/Message/MessageSlice"
import {Committee} from "../../api/CommitteeAPI"
import {getUserByLastName, uploadUserImage} from "../../api/UserAPI"
import {setProperty} from "../../redux/User/AddUserSlice"

interface profileProps {
    type: string,
    pageProfil?: boolean,
    originalEmail?: string
}

export default function ProfileForm(props: profileProps) {
    const dispatch = useAppDispatch()
    const committees = useAppSelector((state) => state.committee.items)
    const addUser = useAppSelector((state: RootState) => state.addUser)
    const imageUserLoading = useAppSelector((state: RootState) => state.addUser.imageUserLoading)

    const getUsersByName = (lastname: string) => {
        getUserByLastName(lastname)
            .then((response) => {
                dispatch(setProperty({"property": "usersByName", "value": response.data}))
            }).catch(() => {
            dispatch(displayMessage({text: "Erreur lors de la récupération des nom", status: "error"}))
        })
    }

    const savePicture = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files !== null && e.target.files.length > 0) {
            if (e.target.files[0].type == "image/jpeg" || e.target.files[0].type == "image/png") {
                dispatch(setProperty({"property": "imageUserLoading", "value": true}))
                const formData = new FormData()
                formData.append("image", e.target.files[0])
                uploadUserImage(formData)
                    .then((response) => {
                        dispatch(setProperty({"property": "imageUserLoading", "value": false}))
                        let currentMonth: string | number = new Date().getMonth() + 1
                        if (currentMonth < 10) {
                            currentMonth = "0" + currentMonth
                        }
                        dispatch(setProperty({
                            "property": "photo",
                            "value": "/img/user/" + new Date().getFullYear() + "/" + currentMonth + "/" + response.data.filename
                        }))
                        dispatch(displayMessage({text: "Image chargée avec succès", status: "success"}))
                    }).catch((result) => {
                    dispatch(setProperty({"property": "imageUserLoading", "value": false}))
                    dispatch(displayMessage({text: result.response.data.error, status: "error"}))
                })
            } else {
                dispatch(setProperty({"property": "photo", "value": ""}))
                dispatch(displayMessage({text: "Votre fichier doit être un JPG ou un PNG", status: "error"}))
            }
        } else {
            dispatch(setProperty({"property": "photo", "value": ""}))
            dispatch(displayMessage({text: "Aucune image n'a été sélectionnée", status: "error"}))
        }
    }

    // Si je suis sur la page de Mon profil, je ne peux voir que certains champs et n'en éditer que certains
    let readonly = false
    if (props.pageProfil) {
        readonly = true
    }

    const handleCommitteesSelect = (committee: Committee, add: boolean) => {
        let selectedCommittees: Committee[] = addUser.committees ?? []
        if (add) {
            selectedCommittees = [...selectedCommittees, committee]
        } else {
            selectedCommittees = selectedCommittees.filter((item) => item.id !== committee.id)
        }
        dispatch(setProperty({"property": "committees", "value": selectedCommittees}))
    }

    return <Fragment>
        <Col lg={4}>
            <Form.Group className="mb-4">
                <Form.Label htmlFor="input-nom">Nom *</Form.Label>
                <Form.Control
                    type="text" value={addUser.lastname}
                    id="input-nom"
                    required
                    placeholder="Rechercher ou ajouter..."
                    onChange={(e) => {
                        dispatch(setProperty({"property": "lastname", "value": e.target.value}))
                        if (e.target.value.length > 5) {
                            getUsersByName(e.target.value)
                        }
                    }}
                    onBlur={(e) => {
                        dispatch(setProperty({"property": "lastname", "value": e.target.value.toUpperCase()}))
                    }}
                />
                <div className="position-absolute bg-white">
                    {addUser.usersByName.map((user, index) => {
                        return <div
                            className="p-2 cursor-pointer"
                            key={index}
                            data-lastname={user.lastname}
                            data-firstname={user.firstname}
                            data-email={user.email}
                            data-civility={user.civility}
                            onClick={(e: React.SyntheticEvent<EventTarget>) => {
                                e.stopPropagation()
                                if (e.target instanceof HTMLDivElement) {
                                    dispatch(setProperty({"property": "lastname", "value": e.target.dataset.lastname}))
                                    dispatch(setProperty({
                                        "property": "firstname",
                                        "value": e.target.dataset.firstname
                                    }))
                                    dispatch(setProperty({"property": "email", "value": e.target.dataset.email}))
                                    dispatch(setProperty({
                                        "property": "civilite",
                                        "value": e.target.dataset.civility === "0" ? "Madame" : "Monsieur"
                                    }))
                                    dispatch(setProperty({"property": "usersByName", "value": []}))
                                }
                            }}>{!user.civility ? "Madame" : "Monsieur"} {user.lastname} {user.firstname} - {user.email}</div>
                    })}
                </div>
            </Form.Group>
        </Col>
        <Col lg={4}>
            <Form.Group className="mb-4">
                <Form.Label htmlFor="input-prenom">Prénom *</Form.Label>
                <Form.Control type="text" value={addUser.firstname} id="input-prenom" required onChange={(e) => {
                    dispatch(setProperty({"property": "firstname", "value": e.target.value}))
                }}/>
            </Form.Group>
        </Col>
        <Col lg={4}>
            <Form.Group className="mb-4">
                <Form.Label htmlFor="input-email">Adresse email *</Form.Label>
                <Form.Control type="email" value={addUser.email} id="input-email" required onChange={(e) => {
                    dispatch(setProperty({"property": "email", "value": e.target.value}))
                }}/>
                {
                    props.type === "admin" && <Form.Check
                        type="checkbox"
                        id="auth-diff-email-radio"
                        label="Autorisation de diffusion"
                        name="auth-diff-email"
                        className="mt-2"
                        checked={addUser.authDiffEmail}
                        onChange={() => {
                            dispatch(setProperty({"property": "authDiffEmail", "value": !addUser.authDiffEmail}))
                        }}
                    />
                }
                {props.pageProfil && props.originalEmail !== "" && props.originalEmail !== addUser.email &&
                    <div className="form-text text-danger pt-0 mb-0 help-text">Cette modification entraînera une
                        déconnexion. Il
                        faudra ensuite vous reconnecter.</div>}
                {!props.pageProfil && props.originalEmail !== "" && props.originalEmail !== addUser.email &&
                    <div className="form-text text-danger pt-0 mb-0 help-text">Cette modification entraînera une
                        déconnexion. Il
                        faudra ensuite que cette personne se reconnecte.</div>}
            </Form.Group>
        </Col>
        <Col lg={4}>
            <Form.Group className="mb-4">
                <Form.Label>Civilité *</Form.Label>
                <div className="d-flex align-items-center col-form-label">
                    <Form.Check
                        type="radio"
                        id="civility-radio"
                        label="Madame"
                        name="civility"
                        className=""
                        required
                        checked={addUser.civilite === "Madame"}
                        onChange={() => {
                            dispatch(setProperty({"property": "civilite", "value": "Madame"}))
                        }}
                    />
                    <Form.Check
                        type="radio"
                        label="Monsieur"
                        id="default radio"
                        name="civility"
                        className="ms-4"
                        checked={addUser.civilite === "Monsieur"}
                        required
                        onChange={() => {
                            dispatch(setProperty({"property": "civilite", "value": "Monsieur"}))
                        }}
                    />
                </div>
            </Form.Group>
        </Col>
        <Col lg={4}>
            <Form.Group className="mb-4">
                <Form.Label className="d-block">Photo</Form.Label>
                {(() => {
                    if (imageUserLoading) {
                        return <Spinner animation="border" variant="primary"/>
                    } else {
                        return <Row className="row-sm row-sm-h">
                            {addUser.photo !== undefined && addUser.photo !== null && addUser.photo !== "" &&
                                <Col xs="auto">
                                    <img alt="" className="rounded-circle object-cover"
                                         style={{width: "64px", height: "64px"}} src={`${data.host}${addUser.photo}`}/>
                                </Col>
                            }
                            <Col>
                                <Row className="row-xs row-xs-h">
                                    <Col xs={12}>
                                        <input type="file" id="input-file-img-user" className="form-control"
                                               onChange={(e) => {
                                                   savePicture(e)
                                               }}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Text className="d-block mt-0 fs-xs">
                                            Fichier jpg, png uniquement. Poids max 3mo.
                                        </Form.Text>
                                    </Col>
                                    {addUser.photo !== undefined && addUser.photo !== null && addUser.photo !== "" &&
                                        <Col xs="auto">
                                            <button
                                                className="btn-less d-block text-danger text-hover-black fw-semibold fs-xs"
                                                onClick={() => {
                                                    dispatch(setProperty({"property": "photo", "value": ""}))
                                                }}>
                                                <i className="mai fs-sm me-2">delete</i> Supp. l'image
                                            </button>
                                        </Col>
                                    }
                                </Row>
                            </Col>
                        </Row>
                    }
                })()}
            </Form.Group>
        </Col>
        {!readonly &&
            <Col lg={4}>
                <Form.Group className="mb-4 pt-4">
                    <div className="col-form-label">
                        <Form.Check type="switch" label="Profil actif" checked={addUser.profilActif} onChange={() => {
                            dispatch(setProperty({"property": "profilActif", "value": !addUser.profilActif}))
                        }}/>
                    </div>
                </Form.Group>
            </Col>
        }
        {
            !readonly && props.type === "other" && addUser.profil === "assistante" &&
            <Col>
                <Form.Group className="mb-4">
                    <Form.Label htmlFor="select-committee">Choix des instances</Form.Label>
                    {
                        committees.map((committee, index) => {
                            const checked = addUser.committees && addUser.committees.some((item) => item.id === committee.id)
                            return <div key={index} className="d-flex">
                                <Form.Check
                                    key={committee.id}
                                    id={`committee-${committee.id}`}
                                    type="switch"
                                    name={`committee-${committee.id}`}
                                    className="ms-3"
                                    label={false}
                                    checked={checked}
                                    onChange={() => handleCommitteesSelect(committee, !checked)}
                                />
                                <Form.Label htmlFor={`committee-${committee.id}`}>{committee.title}</Form.Label>
                            </div>

                        })
                    }
                </Form.Group>
            </Col>
        }
    </Fragment>
}
